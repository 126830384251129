"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.createContainer = void 0;
var _victoryCreateContainer = require("victory-create-container");
var _victoryContainer = require("../components/victory-container");
var _victoryZoomContainer = require("../components/victory-zoom-container");
var _victoryVoronoiContainer = require("../components/victory-voronoi-container");
var _victorySelectionContainer = require("../components/victory-selection-container");
var _victoryBrushContainer = require("../components/victory-brush-container");
var _victoryCursorContainer = require("../components/victory-cursor-container");
const createContainer = exports.createContainer = (0, _victoryCreateContainer.makeCreateContainerFunction)({
  zoom: _victoryZoomContainer.VictoryZoomContainer,
  voronoi: _victoryVoronoiContainer.VictoryVoronoiContainer,
  selection: _victorySelectionContainer.VictorySelectionContainer,
  brush: _victoryBrushContainer.VictoryBrushContainer,
  cursor: _victoryCursorContainer.VictoryCursorContainer
}, _victoryContainer.VictoryContainer);
var _default = exports.default = createContainer;