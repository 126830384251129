"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useGetNativeStyle = exports.omit = exports.default = void 0;
var React = _interopRequireWildcard(require("react"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
/**
 * creates an object with some keys excluded
 * replacement for lodash.omit for performance. does not mimick the entire lodash.omit api
 * @param {Object} originalObject: created object will be based on this object
 * @param {Array<String>} keys: an array of keys to omit from the new object
 * @returns {Object} new object with same properties as originalObject
 */
const omit = function (originalObject, keys) {
  if (keys === void 0) {
    keys = [];
  }
  // code based on babel's _objectWithoutProperties
  const newObject = {};
  for (const key in originalObject) {
    if (keys.indexOf(key) >= 0) {
      continue;
    }
    if (!Object.prototype.hasOwnProperty.call(originalObject, key)) {
      continue;
    }
    newObject[key] = originalObject[key];
  }
  return newObject;
};
exports.omit = omit;
const unsupportedProps = ["pointerEvents", "x", "y", "_x", "_y", "userSelect"];
const unsupportedAndStrokeProps = ["stroke", "strokeWidth", "strokeOpacity", "strokeDasharray", "strokeDashoffset", "strokeLinecap", "strokeLinejoin", ...unsupportedProps];
const getStyle = (style, extraOmitProperties) => {
  if (!style) {
    return undefined;
  }
  // TODO: more style fixes for Native?
  const omitProperties = style.stroke === "none" || style.stroke === "transparent" ? unsupportedAndStrokeProps : unsupportedProps;
  return extraOmitProperties ? omit(style, [...omitProperties, ...extraOmitProperties]) : omit(style, omitProperties);
};
var _default = exports.default = {
  getStyle
};
const useGetNativeStyle = (style, extraOmitProperties) => {
  return React.useMemo(() => getStyle(style, extraOmitProperties), [style, extraOmitProperties]);
};
exports.useGetNativeStyle = useGetNativeStyle;